import type { AuthProfileIsAdminRouteRpc } from '@repo/contract';
import { defineNuxtRouteMiddleware, navigateTo, useCookie } from '#app';
import { sessionTokenKey } from '~/types/session';

export default defineNuxtRouteMiddleware(async () => {
  const token = useCookie(sessionTokenKey);

  console.log('TOKEN(isAdmin): ', token.value);

  const { isAdmin } = await $fetch<typeof AuthProfileIsAdminRouteRpc.response>('/api/rpc/auth/profile/is-admin', {
    method: 'POST',
    headers: {
      token: token.value,
    },
  });

  console.log('isAdmin: ', isAdmin);

  if (!isAdmin) {
    console.log('not admin');
    return navigateTo({
      name: 'not-found',
    });
  }
});
